<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import { required } from "vuelidate/lib/validators";
    import Swal from "sweetalert2";

    /**
     * Add-product component
     */
    export default {

        page: {
            title: "Edit Work Area",
            meta: [
            {
                name: "description",
                content: appConfig.description,
            },
            ],
        },
    
        components: {
            Layout,
            PageHeader

        },

    mounted(){
        this.fetchLocations();
        this.fetchArea();
    },

        methods: {
            fetchLocations() {
                this.$axios.get('/locations-with-area-role')
                .then(response => {
                    let data = response.data.payload;
                    this.locations=data;
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });   
            },
            AddformData() {
                this.areaRoles.push({
                    name: null,
                    payment: null,
                    id:null
                });
            },
            deleteRow(index) {
                this.areaRoles.splice(index, 1);
            },
            deleteRole(role_id , index){
                Swal.fire({
                    title: "Are you sure?",
                    text: "Please confirm that you understand what will happen by deleting role. This role will become inaccessible for the entire organization,  All employees will be removed from this role also Future scheduled shifts for this role will be deleted.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#70C213",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    if (result.value) {
                        this.deletedIds.push(role_id);
                        this.areaRoles.forEach(item => {
                            if(item.id == role_id)
                            {
                                this.areaRoles.splice(index, 1);
                            }
                        });
                    }
                });
                
            },
            fetchArea() {
                this.$axios.get("/area/" + this.$route.params.area_id).then((response) => {
                    let data = response.data.payload;
                    this.areaRoles = data.area_role;
                    this.location_id = data.location_id;
                    this.color = data.color;
                    this.area_name = data.name
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

             updateAreaRole() { 
                let self = this;
                self.$v.$touch();
                if (self.$v.$invalid) {
                    return;
                }  
                if (self.processing) {
                    return;
                }
                self.processing = true;
                self.$axios.post('/area/update/' + this.$route.params.area_id, {
                    area_name: self.area_name,
                    color: self.color,
                    location_id: self.location_id,
                    area_role: self.areaRoles,
                    deletedIds : self.deletedIds,
                })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    
                  self.$router.push("/workarea/listing");
                  

                   
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
    },

       
        },
        data() {
            return {
              
                areaRoles: [{name:null , payment : null}],
                processing:false,
                deletedIds : [],
                title: "Work Area",
                items: [
                    {
                        text: "Area",
                    },
                    {
                        text: "Edit",
                        active: true,
                    },
                ],
                locations:[],
                location_id:null,
                color:'#0a0a0a',
                area_name:null,
            };
        },
        validations:{
            area_name: {required},
            location_id: {required},
            areaRoles:{
                $each:{
                    name: {
                        required,
                    },
                    payment: {
                        required,
                    },
                }
            }
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title" />
        <div class="row">
            <div class="card p-4">
                <b-form>
                 
                            <div class="row" >
                                <div class="col-md-6">
                                <div class="col-md-12 mb-3">
                                    <div class="d-flex align-items-center gap-2">
                                        <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                        </div>
                                        <div class="form-field__wrapper w-100">
                                            <label>Name</label>
                                            <b-input-group class="">
                                                <b-form-input id="input-2" v-model="area_name" placeholder="Enter Name" required
                                                    :class="{'is-invalid':$v.area_name.$error}"></b-form-input>
                                                <b-input-group-prepend is-text class="w-25" >
                                                    <b-form-input id="color" type="color" v-model="color" ></b-form-input>
                                                </b-input-group-prepend>
                                                <div v-if="$v.area_name.$error" class="invalid-feedback" >
                                                    <span v-if="!$v.area_name.required">Name is required.</span>
                                                </div>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </div>
                                        <div class="col-md-12 mt-3" v-for="(role,index) in areaRoles" :key="index">
                                        
                                            <div class="d-flex align-items-center gap-2">
                                                <div class="icon-wrapper mt-3 pt-1">
                                                    <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                                                </div>
                                                <div class="form-field__wrapper col-md-6">
                                                    <b-form-group id="example-date" label="Role/Position" label-for="role">
                                                        <b-form-input id="role" v-model="role.name" placeholder="Enter Role/Position" type="text"
                                                        :class="{'is-invalid':$v.areaRoles.$each[index].name.$error}" ></b-form-input>
                                                        <div v-if="$v.areaRoles.$each[index].name.$error" class="invalid-feedback" >
                                                            <span v-if="!$v.areaRoles.$each[index].name.required">Role/Position is required.</span>
                                                        </div>
                                                    </b-form-group>
                                                </div>
                                                 <div class="form-field__wrapper col-md-5">
                                                    <div class="input-group mt-4">
                                                        <span class="input-group-text">$</span>
                                                        <input id="payment" v-model="role.payment" placeholder="Add Wage Rate Per Role" type="number"
                                                        :class="{'is-invalid':$v.areaRoles.$each[index].payment.$error}" class="form-control" />
                                                        <div v-if="$v.areaRoles.$each[index].payment.$error" class="invalid-feedback" >
                                                            <span v-if="!$v.areaRoles.$each[index].payment.required">Wage Rate is required.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <i v-if="role.id!=null" @click="deleteRole(role.id, index)" class="fas fa-trash f-12 btn mt-4 text-danger"> </i>
                                                <i v-if="role.id==null" @click="deleteRow(index)" class="fas fa-trash f-12 btn mt-4 text-danger"> </i>
                                            </div>
                                        
                                       
                                    </div>
                                     
                             
                                    <div class="col-md-12 mt-3">
                                        <div class="d-flex justify-content-between">
                                            <!-- <div class="delete-fields ms-4" >
                                                <i class="fas fa-trash f-12"> </i>
                                                <input type="button" class="mt-3 mt-lg-0 border-0 bg-white" value="Delete" @click="deleteRow(index)" />
                                            </div> -->
                                            <div class="add-fields">
                                                <i class="fas fa-plus f-12"> </i>
                                                <input type="button" class="mt-3 mt-lg-0 border-0 bg-white" value="Add Multi" @click="AddformData" />
                                            </div>
                                        </div>
                                    </div>
                             
                         
                            </div>

                       <div class="col-md-3" v-if="locations.length>0">                     
                        <label>Select Location</label>
                        <div class="">
                          <select v-model="location_id" id="emp_job_type" class="form-select" :class="{'is-invalid':$v.location_id.$error}">
                            <option :value="location.id" v-for="(location,index) in locations" :key="index" >{{location.name}}</option>
                          </select>
                            <div v-if="$v.location_id.$error" class="invalid-feedback" >
                                <span v-if="!$v.location_id.required">Location is required.</span>
                            </div>
                        </div>
                         
                      </div>
                       <!-- <div class="col-md-3">
                        <div class="">
                          <b-form-group id="example-date" label="Color" label-for="color">
                                                        <b-form-input id="color" v-model="color" type="color"></b-form-input>
                                                    </b-form-group>
                        </div>
                         
                      </div> -->
                        
                      
                            </div>
                      
                    <div class="col-md-12 mt-3">
                        <!-- <b-button type="submit" class="btn bg-white text-dark border-1 radius-30 px-4 me-2">Cancel</b-button> -->
                        <!-- <b-button type="submit" variant="primary" class="gradientColor radius-30 border-0 px-4">Create</b-button> -->
                     <SubmitButton :processing="processing" text="Update" @clicked="updateAreaRole" />
                        
                    </div>
                </b-form>
            </div>
        </div>
    </Layout>
</template>
<style scoped>
.input-group-text {
    height: 40px !important;
}
</style>
